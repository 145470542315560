.actionButtons {
    display: flex;
    justify-content: space-between;
}

.actionButton {
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
}



@media (max-width: 600px) {
     

    .actionButtons {
        flex-direction: column;
        text-align: center;
    }
    .PHT{
        overflow-x:scroll;
        font-size: xx-small;
    }
   
}
@media print {
    /* Set the page size to A4 */
    @page {
      size: A4;
      margin: 0; /* Remove default margins */
    }
  
    /* Set the content size to fit within the page */
   
  }
  @media print {
    #button {
        display: none;
    }
    
}