.form-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="file"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-top: 3px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .loader{
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content:center;
    align-items: center;
    background: rgba(255, 255, 255, 0.70);  top: 0;
    backdrop-filter: blur(5px);
    z-index: 100;
    flex-direction: column;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    .forumNav{
      position: absolute !important;
      left:35% !important;
    }
    .qno{
      margin-left: 10% !important;
    }
    .qdiv{
      margin-left:  10% !important;
      margin-right: 10% !important;
    }
    .qlist{
      font-size: large !important;
    }
    .ansDescrip{
      font-size: small !important;
    }
    .forum-modal{
      width: 400px !important;
    }
  } 
  .qlist{
    font-size: 25px;
  }