.navBox {
    position: absolute;
  top: 60px;
  right: 10px;
  /* background-color:red; */
  border: 1px solid #ccc;
  padding: 10px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  }

  
  
  
  