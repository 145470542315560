.table-scroll::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
    /* margin-top: 30px; */
  }
  @media screen and (max-width: 750px) {
    .table-scroll {
      
      margin-top: 30px;
      width: 100% !important;
    }

    .dlB{
      width: 25px !important;
      font-size: x-small !important;
    }
    }
    
  
  