

.dBox {
  width: 250px;
  height: 150px;
  border: 1px solid #000;
  margin-bottom: 40px;
  /* margin-right: 35px; */
}

.inner-box {
  display: flex;
  height: 100%;
}

.half {
  /* flex: 1; */
  height: 100%;
  width: 30%;
}
.Dhalf{
    height: 100%;
    width: 70%;
}

.yellow-bg {
  background-color: yellow;
}

.dText {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  background-color: white;
padding-left: 15px;
}

@media (max-width:750px) {
  .dHeading{
      margin-top: 40px;
  }
  .dBox{
     width: auto ;
     height: 100px ;
  }
  .dContainer{
      display: block;
      overflow-y: scroll;
      /* display: flex; */
  }
  .dText{
      font-size: medium;
  }
  .dInnerContainer{
      display: flex;
      flex-direction: column;
      margin-left: 0px !important;
  }
 

}

